const articles = [
  {
    id: '1',
    name: 'master-data',
    title: 'Master Data',
    date: '03-01-2023',
    desc: 'Awal memulai menggunakan program toko, software toko,aplikasi toko database master .',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (2).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (2).webp',
    content: [
      `Master database untuk kebutuhan input dan memasukan data pelanggan, customer, user pengguna, dan proteksi password, lokasi gudang area tempat penyimpanan produk , penerapan penggunaan pajak untuk penjualan misalnya dengan penggunaan PPN system dalam melakukan transaksi..`,
    ],
  },
  {
    id: '2',
    name: 'produk-stok',
    title: 'Produk dan stok',
    desc: 'Memasukan data produk untuk dijual dan kebutuhan inventori management lain nya.',
    date: '03-01-2023',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (1).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (1).webp',
    content: [
      `Selanjutnya kita dapat memasukan daftar dan data produk untuk dijual, dilengkapi dengan fitur dan menu seperti penggunaan gambar foto produk, penerapan konsep barcode system, penggunaan rakitan produk, pilihan produk seperti warna ukuran dan lain sebagai nya, serta kebutuhan inventori management lain.`,
    ],
  },
  {
    id: '3',
    name: 'inventori',
    title: 'Inventori Management',
    date: '03-01-2023',
    desc: 'Transaksi inventori management masuk keluar barang stok persediaan produk.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (8).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (8).webp',
    desc: 'Built modern car service and limousine with progresive website application PWA for SEO best solution ',
    content: [
      `Fitur yang digunakan dalam kebutuhan untuk melakukan transaksi inventori management, seperti memasukan produk persediaan stok barang dan melakukan pengeluaran, dilengkapi dengan berbagai kebutuhan ,seperti penggunaan transaksi in stok masuk untuk kebutuhan pembelian, dan lain sebagai nya, selanjutnya transaksi out stok untuk kebutuhan dalam pengelolahan stok untuk produk yang rusak expired maupun hilang.`,
    ],
  },
  {
    id: '4',
    name: 'e-catalog',
    title: 'E-catalog',
    date: '03-01-2023',
    desc: 'Dilengkapi dengan E-catalog produk.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (9).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (9).webp',
    content: [
      `Semakin sempurna dengan e-catalog fitur dalam bekerja, seperti kemudahan untuk mencetak seluruh produk jual lengkap dengan harga jual dan gambar produk, yang dapat kita cetak sebagai peganggan pada karyawan untuk price list, maupun untuk dibagikan kepada pelanggan.`,
    ],
  },
  {
    id: '5',
    name: 'barcode',
    title: 'Barcode Label',
    date: '03-01-2023',
    desc: 'Semakin mudah mencetak barcode label dengan fitur labeled barcode.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (10).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (10).webp',
    content: [
      `Barcode label memudahkan dalam memberikan informasi kepada pengunjung toko minimarket dengan id barang dan produk , dengan sticker label maupun kertas biasa kita dapat mencetak dan menempelkan ke rak display produk untuk memberikan inforasmi mengenai produk barang.`,
    ],
  },
  {
    id: '5',
    name: 'penjualan-kasir',
    title: 'Penjualan Kasir',
    date: '03-01-2023',
    desc: 'Fitur penjualan kasir modern dengan kemdudahan bekerja via touchscreen produk dan barcode system.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (3).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (3).webp',
    content: [
      `Penjualan kasir yang sangat mudah dan cepat dalam membantu kasir mencatat penjualan kasir pelanggan dengan cepat, kemudahan dengan skema dan konsep bekerja via touchscreen metode dengan cara menyentuh foto gambar produk , maupun dalam penggunaan barcode metode system untuk digunakan saat transaksi kasir.`,
    ],
  },
  {
    id: '6',
    name: 'transaksi-kasir',
    title: 'Transaksi Kasir',
    date: '03-01-2023',
    desc: 'Metode transaksi kasir keren dan modern dengan gambar mata uang.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (7).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (7).webp',
    content: [
      `Semakin mudah untuk melakukan transaksi pada kasir , karna sudah mendukung penuh multi payment, seperti transaksi via debit credit card, check, hingga cash dengan gambar mata uang rupiah semakin keren dalam digunakan menunjang pembayaran pada kasir point of sale.`,
    ],
  },
  {
    id: '7',
    name: 'cash-flow',
    title: 'Arus Kas Keuangan',
    date: '03-01-2023',
    desc: 'Transaksi kas keluar dan masuk pendapatan dengan cash flow.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (4).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (4).webp',
    content: [
      `Dan sudah include dengan arus kas untuk input dan memasukan data transaksi pengelolahan keuangan keluar, maupun untuk pendapatan atau pemasukan, dengan metode transaksi yang sangat mudah digunakan dalam mencata semua kas keuangan.`,
    ],
  },
  {
    id: '8',
    name: 'cloase-cash',
    title: 'Close Cash',
    date: '03-01-2023',
    desc: 'Fungsi yang digunakan untuk satle semua transaksi .',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (6).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (6).webp',
    content: [
      `Selanjutnya kita dapat menggunakan fitur tutup kasir, untuk digunakan dalam handling semua pendapatan dari penjualan kasir ditambah dengan pendapatan via arus kas keuangan pemasukan income, dan kemudian di akumulasikan dengan pengurangan kas keluar untuk mendapatkan detail cash flow pada arus kas ini , sehingga nantinya akan di total secara keseluruhan berapa pendapatan toko minimarket.`,
    ],
  },
  {
    id: '9',
    name: 'laporan-stok',
    title: 'Laporan Stok',
    date: '03-01-2023',
    desc: 'Cek semua laporan persediaan produk dan barang pada menu laporan stok inventori management.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (1).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (1).webp',
    content: [
      `Dan kita pun dapat dengan mudah cek seluruh rangkuman laporan inventori stok management, yang terdapat pada fitur stok dan produk , tinggal klik salah satu menu laporan stok , maka nantinya akan ditampilkan secara keseluruhan detail inventori stok management persediaan barang berdasarkan report yang dipilih.`,
    ],
  },
  {
    id: '9',
    name: 'laporan-keuangan',
    title: 'Laporan Keuangan',
    date: '03-01-2023',
    desc: 'Cek pendapatan detail income omset via laporan penjualan detail.',
    thumb: 'https://mesinkasir.github.io/img/thumb/programtoko (5).webp',
    img: 'https://mesinkasir.github.io/img/programtoko (5).webp',
    content: [
      `Dan kemudahan untuk owner dalam cek laporan laporan detail khusus nya pada penjualan, dan tentu saja include dengan laba rugi untuk monitor pendapatan dari bisnis dan usaha, disini tersedia beberapa laporan yang dapat dipilih berdasarkan kebutuhan owner dalam cek laporan, dan jika dibutuhkan dapat dilakukan update ke online system untuk kemudahan owner cek laporan dari berbagai device termasuk smartphone android iphone , plus integrasi dengan website informasi toko dengan melakukan upgrade ke online mode.`,
    ],
  },
  {
    id: '10',
    name: 'online',
    title: 'Update Online Mode',
    date: '03-01-2023',
    desc: 'Upgrade ke online mode integrasi web app pos point of sale modern system.',
    thumb: 'https://mesinkasir.github.io/assets/img/thumb/toko14.webp',
    img: 'https://mesinkasir.github.io/assets/img/toko14.webp',
    content: [
      `Sempurna dengan melakukan upgrade dan update ke hybrid online mode dalam kebutuhan integrasi total dengan berbagai teknologi, baik penggunaan desktop software program toko , plus integrasi aplikasi toko kasir online, dan website informasi dengan content management system dalam bekerja, dengan kemudahan membuat website sesuai dengan kreasi sendiri, dan kemudahan untuk cek laporan dimana saja secara real time online dengan update dan upgrade ke cloud mode.`,
    ],
  },
];

export default articles;
